


































import Vue from 'vue';
import LoginCard from '@/components/specific/LoginCard.vue';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';

const GoogleService = ServiceFactory.get('google');

export default Vue.extend({
  name: 'LinkGoogleCalendar',

  components: {
    LoginCard,
  },

  data() {
    return {};
  },

  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
  },

  methods: {
    ...UICommonMapper.mapActions(['setMessage']),

    async goGoogleOAuth() {
      const self = this;

      try {
        const response = await GoogleService.getAuthUrl();
        const authUrl = this._.get(response, 'authUrl');
        // GoogleServiceから返ってきたURLに遷移する。
        window.location.href = authUrl;
      } catch (error) {
        self.$$log.error(error);
        self.setMessage({ color: 'error', text: error.message });
      }
    },

    toHome() {
      this.$router.push('/');
    },
  },
});
